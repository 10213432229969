import { generateUUID } from '../../helpers/utils';
import { getNewCard } from '../../mutations/widgets/card';
import { WidgetAlignment, ContentAlignment, SectionLayout } from '../../types';
import { BatchCommands, getSharedTemplate } from './batchCommands';
import {
  TemplatesEnum,
  BUTTON_STYLE_HOMEPAGE_TEMPLATE,
  TITLE_HOMEPAGE_TEMPLATE,
  TEXT_HOMEPAGE_TEMPLATE,
  BUTTON_TEXT_HOMEPAGE_TEMPLATE,
  DEFAULT_TEXT_HERO_TEMPLATE,
  DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
  DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
  DEFAULT_TEXT_TEXT_TEMPLATE,
  DEFAULT_SECTION_TITLE,
  DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
  DAM_TITLE,
  DAM_TEXT,
  ASSET_BANK_TITLE,
  ASSET_BANK_TEXT,
  LINKS_TITLE,
  LINKS_TEXT,
  LINK_HOMEPAGE_TEMPLATE,
  OTHER_LINK_BUTTON,
  EXPRESS_TITLE,
  EXPRESS_INFO_TEXT,
  FOOTER_TEXT,
  AUDIENCE_TEXT,
  AUDIENCE_TITLE,
  CONTACT_BUTTON,
  DEMO_BUTTON,
  EARLY_ACCESS_BUTTON,
  GTM_TEXT,
  GTM_TITLE,
  INDUSTRY_TEXT,
  INDUSTRY_TITLE,
  LAUNCH_TEXT,
  LAUNCH_TITLE,
  PARTNER_TEXT,
  PARTNER_TITLE,
  PREORDER_BUTTON,
  PRODUCT_TEXT,
  PRODUCT_TITLE,
  QUESTIONS_TITLE,
  QUESTION_TEXT,
  RESOURCE_BUTTON,
  REVIEWS_BUTTON,
  ROADMAP_BUTTON,
  SERVE_BUTTON,
  STRATEGY_BUTTON,
  SUCCESS_TEXT,
  SUCCESS_TITLE,
  USERS_TITLE,
  USER_TEXT,
  WAIT_TEXT,
  WAIT_TITLE,
} from './constants';

export const TemplateBuilder = (
  guideId: string,
  chapterId: string,
  pageId: string,
  sectionId: string,
  guideVersion: number,
  sectionTemplate: TemplatesEnum,
  sectionPosition: number,
  guideTitle?: string,
) => {
  const {
    addNewSectionToPage,
    changeSectionLayout,
    editSectionWidth,
    editSectionHeight,
    editSectionContentAlignment,
    editSectionHorizontalPadding,
    addNewTextWidgetToSection,
    editSectionContentSpacing,
    editSectionColumnSpacing,
    addNewButtonWidgetToSection,
    addNewCardWidgetToSection,
    addNewButtonsToButtonWidget,
    createLandingPage,
    editSectionVerticalPadding,
    addPageToChapter,
  } = BatchCommands(guideId, chapterId, pageId, sectionId, guideVersion);

  const sharedTemplate = getSharedTemplate(
    guideId,
    chapterId,
    pageId,
    sectionId,
    guideVersion,
  );

  const secondSectionId = generateUUID();
  const thirdSectionId = generateUUID();
  const fourthSectionId = generateUUID();
  const fifthSectionId = generateUUID();
  const sixthSectionId = generateUUID();
  const seventhSectionId = generateUUID();

  switch (sectionTemplate) {
    case TemplatesEnum.PL_TEMPLATE: {
      return [
        createLandingPage(guideTitle),
        editSectionWidth(1200),
        editSectionHeight(120),
        editSectionVerticalPadding(48),
        changeSectionLayout(SectionLayout.DOUBLE_33_66),
        addNewTextWidgetToSection(WAIT_TITLE),
        addNewTextWidgetToSection(WAIT_TEXT),
        addNewButtonWidgetToSection(
          PREORDER_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          'justify-content:left;\n',
        ),

        addNewCardWidgetToSection(
          [
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
            getNewCard(),
          ],
          1,
          WidgetAlignment.CENTER,
          sectionId,
          240,
        ),

        // second section
        addNewSectionToPage(1, secondSectionId),
        editSectionWidth(1200, secondSectionId),
        editSectionHeight(120, secondSectionId),
        editSectionVerticalPadding(48, secondSectionId),
        addNewTextWidgetToSection(PRODUCT_TITLE, 0, secondSectionId),
        addNewTextWidgetToSection(PRODUCT_TEXT, 0, secondSectionId),
        addNewCardWidgetToSection(
          [getNewCard()],
          0,
          WidgetAlignment.CENTER,
          secondSectionId,
          790,
        ),
        addNewButtonWidgetToSection(
          DEMO_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          secondSectionId,
        ),

        // third section
        addNewSectionToPage(2, thirdSectionId),
        editSectionWidth(1200, thirdSectionId),
        editSectionHeight(120, thirdSectionId),
        editSectionVerticalPadding(48, thirdSectionId),
        addNewTextWidgetToSection(USERS_TITLE, 0, thirdSectionId),
        addNewTextWidgetToSection(USER_TEXT, 0, thirdSectionId),
        addNewCardWidgetToSection(
          [
            getNewCard(
              generateUUID(),
              'User 1',
              "I've never seen anything like [Product Name]. It's completely changed the way I [specific outcome]",
            ),
            getNewCard(
              generateUUID(),
              'User 2',
              'The [specific feature] of [Product Name] is a game-changer. Can’t wait for the full release!',
            ),
          ],
          0,
          WidgetAlignment.CENTER,
          thirdSectionId,
        ),
        addNewButtonWidgetToSection(
          REVIEWS_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          thirdSectionId,
        ),

        // fourth section
        addNewSectionToPage(3, fourthSectionId),
        editSectionWidth(1200, fourthSectionId),
        editSectionHeight(120, fourthSectionId),
        editSectionVerticalPadding(48, fourthSectionId),
        addNewTextWidgetToSection(QUESTIONS_TITLE, 0, fourthSectionId),
        addNewTextWidgetToSection(QUESTION_TEXT, 0, fourthSectionId),
        addNewButtonWidgetToSection(
          CONTACT_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          fourthSectionId,
        ),

        // fifth section
        addNewSectionToPage(4, fifthSectionId),
        editSectionWidth(1200, fifthSectionId),
        editSectionHeight(120, fifthSectionId),
        editSectionVerticalPadding(64, fifthSectionId),
        addNewTextWidgetToSection(FOOTER_TEXT, 0, fifthSectionId),
      ];
    }

    case TemplatesEnum.GTM_TEMPLATE: {
      return [
        createLandingPage(guideTitle),
        editSectionWidth(1200),
        editSectionHeight(120),
        editSectionVerticalPadding(96),
        addNewTextWidgetToSection(GTM_TITLE),
        addNewTextWidgetToSection(GTM_TEXT),
        addNewButtonWidgetToSection(
          EARLY_ACCESS_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
        ),

        // second section
        addNewSectionToPage(1, secondSectionId),
        editSectionWidth(1200, secondSectionId),
        editSectionHeight(120, secondSectionId),
        editSectionVerticalPadding(48, secondSectionId),
        changeSectionLayout(SectionLayout.DOUBLE_66_33, secondSectionId),
        addNewTextWidgetToSection(INDUSTRY_TITLE, 0, secondSectionId),
        addNewTextWidgetToSection(INDUSTRY_TEXT, 0, secondSectionId),
        addNewButtonWidgetToSection(
          STRATEGY_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          'justify-content:left;\n',
          secondSectionId,
          0,
        ),
        addNewCardWidgetToSection(
          [getNewCard(generateUUID(), '', '', '')],
          1,
          WidgetAlignment.CENTER,
          secondSectionId,
        ),

        // third section
        addNewSectionToPage(2, thirdSectionId),
        editSectionWidth(1200, thirdSectionId),
        editSectionHeight(120, thirdSectionId),
        editSectionVerticalPadding(48, thirdSectionId),
        addNewTextWidgetToSection(LAUNCH_TITLE, 0, thirdSectionId),
        addNewTextWidgetToSection(LAUNCH_TEXT, 0, thirdSectionId),
        addNewCardWidgetToSection(
          [
            getNewCard(
              generateUUID(),
              'Phase 1: Pre-Launch',
              'Detail your market research, product development, and pre-launch buzz.',
            ),
            getNewCard(
              generateUUID(),
              'Phase 2: Launch',
              'Describe your launch event, marketing channels, and initial sales strategy.',
            ),
            getNewCard(
              generateUUID(),
              'Phase 3: Post-Launch',
              'Explain your approach to customer feedback, updates, and scaling.',
            ),
          ],
          0,
          WidgetAlignment.CENTER,
          thirdSectionId,
        ),
        addNewButtonWidgetToSection(
          ROADMAP_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          thirdSectionId,
          0,
        ),

        // fourth section
        addNewSectionToPage(3, fourthSectionId),
        editSectionWidth(1200, fourthSectionId),
        editSectionHeight(120, fourthSectionId),
        editSectionVerticalPadding(48, fourthSectionId),
        addNewTextWidgetToSection(AUDIENCE_TITLE, 0, fourthSectionId),
        addNewTextWidgetToSection(AUDIENCE_TEXT, 0, fourthSectionId),
        addNewCardWidgetToSection(
          [
            getNewCard(
              generateUUID(),
              'Segment 1: [Segment Name]',
              'Explain the value proposition for another key segment',
            ),
            getNewCard(
              generateUUID(),
              'Segment 2: [Segment Name]',
              'Describe your launch event, marketing channels, and initial sales strategy.',
            ),
            getNewCard(
              generateUUID(),
              'Segment 3: [Segment Name]',
              'Highlight how you meet the needs of this audience.',
            ),
          ],
          0,
          WidgetAlignment.CENTER,
          fourthSectionId,
        ),
        addNewButtonWidgetToSection(
          SERVE_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          fourthSectionId,
          0,
        ),

        // fifth section
        addNewSectionToPage(4, fifthSectionId),
        editSectionWidth(1200, fifthSectionId),
        editSectionHeight(120, fifthSectionId),
        editSectionVerticalPadding(48, fifthSectionId),
        addNewTextWidgetToSection(SUCCESS_TITLE, 0, fifthSectionId),
        addNewTextWidgetToSection(SUCCESS_TEXT, 0, fifthSectionId),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Segment 1: [Segment Name]'),
            getNewCard(generateUUID(), 'Segment 2: [Segment Name]'),
            getNewCard(generateUUID(), 'Segment 3: [Segment Name]'),
          ],
          0,
          WidgetAlignment.CENTER,
          fifthSectionId,
        ),
        addNewButtonWidgetToSection(
          RESOURCE_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          fifthSectionId,
          0,
        ),

        // sixth section
        addNewSectionToPage(5, sixthSectionId),
        editSectionWidth(1200, sixthSectionId),
        editSectionHeight(120, sixthSectionId),
        editSectionVerticalPadding(48, sixthSectionId),
        addNewTextWidgetToSection(PARTNER_TITLE, 0, sixthSectionId),
        addNewTextWidgetToSection(PARTNER_TEXT, 0, sixthSectionId),
        addNewButtonWidgetToSection(
          CONTACT_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          sixthSectionId,
          0,
        ),

        // seventh section
        addNewSectionToPage(6, seventhSectionId),
        editSectionWidth(1200, seventhSectionId),
        editSectionHeight(120, seventhSectionId),
        editSectionVerticalPadding(64, seventhSectionId),
        addNewTextWidgetToSection(FOOTER_TEXT, 0, seventhSectionId),
      ];
    }

    case TemplatesEnum.HOMEPAGE_TEMPLATE: {
      return [
        createLandingPage(guideTitle),
        // first section
        editSectionWidth(1200),
        editSectionHeight(120),
        editSectionVerticalPadding(96),
        addNewTextWidgetToSection(DAM_TITLE),
        addNewTextWidgetToSection(DAM_TEXT),
        addNewButtonWidgetToSection(
          BUTTON_TEXT_HOMEPAGE_TEMPLATE,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          'justify-content:left;\n',
        ),

        // second section
        addNewSectionToPage(1, secondSectionId),
        editSectionVerticalPadding(48, secondSectionId),
        changeSectionLayout(SectionLayout.DOUBLE_66_33, secondSectionId),
        editSectionWidth(1200, secondSectionId),
        editSectionHeight(120, secondSectionId),
        addNewTextWidgetToSection(ASSET_BANK_TITLE, 0, secondSectionId),
        addNewTextWidgetToSection(ASSET_BANK_TEXT, 0, secondSectionId),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Asset Category A', '', ''),
            getNewCard(generateUUID(), 'Asset Category B', '', ''),
            getNewCard(generateUUID(), 'Asset Category C', '', ''),
            getNewCard(generateUUID(), 'Asset Category D', '', ''),
          ],
          0,
          WidgetAlignment.CENTER,
          secondSectionId,
        ),
        addNewTextWidgetToSection(LINKS_TITLE, 1, secondSectionId),
        addNewTextWidgetToSection(LINKS_TEXT, 1, secondSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, secondSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, secondSectionId),
        addNewTextWidgetToSection(LINK_HOMEPAGE_TEMPLATE, 1, secondSectionId),

        addNewButtonWidgetToSection(
          OTHER_LINK_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          'justify-content:left;\n',
          secondSectionId,
          1,
        ),
        addNewButtonWidgetToSection(
          OTHER_LINK_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          'justify-content:left;\n',
          secondSectionId,
          1,
        ),
        addNewButtonWidgetToSection(
          OTHER_LINK_BUTTON,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          'justify-content:left;\n',
          secondSectionId,
          1,
        ),

        // third section
        addNewSectionToPage(2, thirdSectionId),
        editSectionWidth(1200, thirdSectionId),
        editSectionHeight(120, thirdSectionId),
        changeSectionLayout(SectionLayout.DOUBLE_50_50, thirdSectionId),
        editSectionVerticalPadding(48, thirdSectionId),
        addNewTextWidgetToSection(EXPRESS_TITLE, 0, thirdSectionId),
        addNewTextWidgetToSection(EXPRESS_INFO_TEXT, 0, thirdSectionId),
        addNewCardWidgetToSection(
          [getNewCard(generateUUID(), 'Share files', '', '')],
          1,
          WidgetAlignment.CENTER,
          thirdSectionId,
        ),

        // fourth section
        addNewSectionToPage(3, fourthSectionId),
        editSectionWidth(1200, fourthSectionId),
        editSectionHeight(120, fourthSectionId),
        addNewTextWidgetToSection(FOOTER_TEXT, 0, fourthSectionId),
      ];
    }

    case TemplatesEnum.GUIDE_PAGE_TEMPLATE: {
      const firstSectionId = generateUUID();

      return [
        addPageToChapter(guideTitle),
        addNewSectionToPage(0, firstSectionId),
        editSectionWidth(1200, firstSectionId),
        editSectionHeight(120, firstSectionId),
        editSectionVerticalPadding(96, firstSectionId),
        editSectionContentAlignment(ContentAlignment.CENTER, firstSectionId),
        addNewTextWidgetToSection(TITLE_HOMEPAGE_TEMPLATE, 0, firstSectionId),
        addNewTextWidgetToSection(TEXT_HOMEPAGE_TEMPLATE, 0, firstSectionId),
        addNewButtonWidgetToSection(
          BUTTON_TEXT_HOMEPAGE_TEMPLATE,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
          generateUUID(),
          '',
          firstSectionId,
        ),

        ...sharedTemplate,
      ];
    }

    case TemplatesEnum.LANDING_PAGE_TEMPLATE: {
      return [
        // first section
        createLandingPage(guideTitle),
        editSectionWidth(1200),
        editSectionHeight(120),
        editSectionVerticalPadding(96),
        editSectionContentAlignment(ContentAlignment.CENTER),
        addNewTextWidgetToSection(TITLE_HOMEPAGE_TEMPLATE),
        addNewTextWidgetToSection(TEXT_HOMEPAGE_TEMPLATE),
        addNewButtonWidgetToSection(
          BUTTON_TEXT_HOMEPAGE_TEMPLATE,
          BUTTON_STYLE_HOMEPAGE_TEMPLATE,
        ),

        ...sharedTemplate,
      ];
    }
    case TemplatesEnum.HERO_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        editSectionHeight(600),
        editSectionContentAlignment(ContentAlignment.CENTER),
        addNewTextWidgetToSection(DEFAULT_TEXT_HERO_TEMPLATE),
        addNewButtonWidgetToSection(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
        ),
      ];

    case TemplatesEnum.TWO_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        addNewCardWidgetToSection([
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
        ]),
      ];
    case TemplatesEnum.FOUR_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(2048),
        addNewCardWidgetToSection([
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
          getNewCard(generateUUID(), 'Title', 'Description'),
        ]),
      ];
    case TemplatesEnum.SIX_CARD_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(1200),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
          ],
          0,
        ),
        addNewCardWidgetToSection(
          [
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
            getNewCard(generateUUID(), 'Title', 'Description'),
          ],
          0,
        ),
      ];
    case TemplatesEnum.TEXT_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 1),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 2),
        editSectionHorizontalPadding(16),
        editSectionContentSpacing(8),
        editSectionColumnSpacing(20),
      ];
    case TemplatesEnum.TWO_COLUMN_FEATURED_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        addNewTextWidgetToSection(DEFAULT_SECTION_TITLE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewCardWidgetToSection(
          [getNewCard(generateUUID(), '', '')],
          1,
          WidgetAlignment.LEFT,
        ),
      ];
    case TemplatesEnum.HERO_WITH_IMAGE_TEMPLATE: {
      const widgetId = generateUUID();

      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.DOUBLE_50_50),
        editSectionContentAlignment(ContentAlignment.CENTER),
        addNewTextWidgetToSection(DEFAULT_SECTION_TITLE, 0),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewButtonWidgetToSection(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_TEMPLATE,
          widgetId,
          'justify-content:left;\n',
        ),
        addNewButtonsToButtonWidget(
          DEFAULT_BUTTON_TEXT_HERO_TEMPLATE,
          DEFAULT_BUTTON_STYLE_HERO_SECONDARY_TEMPLATE,
          widgetId,
        ),
        addNewCardWidgetToSection(
          [getNewCard(generateUUID(), '', '')],
          1,
          WidgetAlignment.LEFT,
        ),
      ];
    }
    case TemplatesEnum.FOOTER_WITH_IMAGE_TEMPLATE:
      return [
        addNewSectionToPage(sectionPosition),
        editSectionWidth(800),
        changeSectionLayout(SectionLayout.TRIPLE_33_33_33),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 0),
        addNewCardWidgetToSection([getNewCard(generateUUID(), '', '')], 1),
        addNewTextWidgetToSection(DEFAULT_TEXT_TEXT_TEMPLATE, 2),
      ];
  }
};
