import React from 'react';
import { useDebounce } from 'use-debounce';
import { IconUserGroup, IconUser, IconSearch, IconShield } from '@bynder/icons';
import {
  Combobox,
  Dropdown,
  Form,
  Spinner,
  Thumbnail,
} from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import { Identity, IdentityAccess, IdentityType, RoleType } from '../../types';
import { useEntityPermission } from '../../queries/permissions';
import { useSearchIdentities } from '../../queries/identity';
import {
  MAX_LIMIT_PERMISSION_ENTITIES,
  SEARCH_DEBOUNCE_WHILE_TYPING,
} from '../../constants/app';
import { AccessModalContext } from '../../context/accessmodal';
import { useShareSuggestionsPySettings } from '../../queries/settings';

export const AccessModalSearch: React.FC = () => {
  const { editIdentity, identityList, entityId } =
    React.useContext(AccessModalContext);
  const [searchTerm, setSearchTerm] = React.useState('');
  const { translate } = useTranslate();
  const [debouncedSearchTerm] = useDebounce(
    searchTerm,
    SEARCH_DEBOUNCE_WHILE_TYPING,
  );
  const { data: shareSuggestionsSettings, isLoading: isLoadingSettings } =
    useShareSuggestionsPySettings();
  const { data: entityPermissions, isError } = useEntityPermission(entityId);
  const [users, groups, profiles] = useSearchIdentities(
    debouncedSearchTerm,
    shareSuggestionsSettings,
  );

  const { documentOwnerId } = entityPermissions || {};
  const { data: userIdentities, isLoading: isLoadingUsers } = users;
  const { data: groupIdentites, isLoading: isLoadingGroups } = groups;
  const { data: profileIdentites, isLoading: isLoadingProfiles } = profiles;

  const handleOnSelectIdentity = (
    identity: Identity,
    identityType: IdentityType,
  ) => {
    const onSelectIdentity = (newIdentity: Identity) => {
      const newList = [...identityList];
      const identityAccess: IdentityAccess = {
        ...newIdentity,
        role: RoleType.READER,
        inherited: false,
      };
      newList.push(identityAccess);
      editIdentity(newList);
    };
    onSelectIdentity({ ...identity, identity: identityType });
  };

  const handleSelect = (identity: Identity, identityType: IdentityType) => {
    setSearchTerm('');
    handleOnSelectIdentity(identity, identityType);
  };

  const renderLoading = () => (
    <Dropdown.ListItem
      isDisabled
      thumbnail={
        <Thumbnail variant="clean" icon={<Spinner />} shape="circle" size="m" />
      }
    >
      Searching...
    </Dropdown.ListItem>
  );

  const renderNoResults = () => (
    <Dropdown.ListItem
      isDisabled
      thumbnail={
        <Thumbnail
          variant="clean"
          icon={<IconSearch />}
          shape="circle"
          size="m"
        />
      }
    >
      <Translate id="ACCESS_MODAL_SEARCH.NO_RESULTS" />
    </Dropdown.ListItem>
  );

  const renderUsers = () => {
    if (isLoadingUsers) return null;

    return userIdentities.map(user => (
      <Dropdown.ListItem
        isDisabled={
          documentOwnerId === user.id ||
          identityList
            .map((identity: Identity) => identity?.id)
            .includes(user.id)
        }
        onClick={() => handleSelect(user, IdentityType.USER)}
        key={user.id}
        subtext={user.email}
        thumbnail={
          <Thumbnail
            variant="clean"
            icon={<IconUser />}
            shape="circle"
            size="m"
          />
        }
      >
        {user.full_name}
      </Dropdown.ListItem>
    ));
  };
  const renderGroups = () => {
    if (isLoadingGroups || !shareSuggestionsSettings?.groupSuggestions)
      return null;

    return groupIdentites.map(group => (
      <Dropdown.ListItem
        isDisabled={identityList
          .map((identity: Identity) => identity?.id)
          .includes(group.id)}
        onClick={() => handleSelect(group, IdentityType.GROUP)}
        key={group.id}
        thumbnail={
          <Thumbnail
            variant="clean"
            icon={<IconUserGroup />}
            shape="circle"
            size="m"
          />
        }
      >
        {group.name}
      </Dropdown.ListItem>
    ));
  };
  const renderProfiles = () => {
    if (isLoadingProfiles || !shareSuggestionsSettings?.profileSuggestions)
      return null;

    return profileIdentites.map(profile => (
      <Dropdown.ListItem
        isDisabled={identityList
          .map((identity: Identity) => identity?.id)
          .includes(profile.id)}
        key={profile.id}
        onClick={() => handleSelect(profile, IdentityType.PROFILE)}
        thumbnail={
          <Thumbnail
            variant="clean"
            icon={<IconShield />}
            shape="circle"
            size="m"
          />
        }
      >
        {profile.name}
      </Dropdown.ListItem>
    ));
  };

  if (isError) {
    return null;
  }

  const limitReached = identityList.length >= MAX_LIMIT_PERMISSION_ENTITIES;

  return (
    <Form.Group>
      <Combobox
        isDisabled={limitReached}
        openOn="type"
        placeholder={translate('ACCESS_MODAL_SEARCH.PLACEHOLDER_TEXT')}
        onChange={setSearchTerm}
        value={searchTerm}
      >
        {(isLoadingGroups || isLoadingProfiles || isLoadingUsers) &&
          renderLoading()}
        {userIdentities?.length === 0 &&
          groupIdentites?.length === 0 &&
          profileIdentites?.length === 0 &&
          !isLoadingSettings &&
          shareSuggestionsSettings?.userSuggestions &&
          shareSuggestionsSettings?.groupSuggestions &&
          shareSuggestionsSettings?.profileSuggestions &&
          renderNoResults()}
        {renderUsers()}
        {renderGroups()}
        {renderProfiles()}
      </Combobox>
      {limitReached && (
        <Form.HelperText icon={<Form.IconError />} isInvalid>
          <Translate id="ACCESS_MODAL_SEARCH.LIMIT_RECIPIENTS_TEXT" />
        </Form.HelperText>
      )}
    </Form.Group>
  );
};
