import React from 'react';
import { Button, ModalBase, Card, token, Tooltip } from '@bynder/design-system';
import { Translate, useTranslate } from '@bynder/localization';
import PrimaryButton from '../shared/PrimaryButton';
import { useInspectorStore } from '../../stores/inspectorStore';
import styled from 'styled-components';
import whiteTemplate from '../../assets/whiteTemplate.png';
import textTemplate from '../../assets/textTemplate.svg';
import cardTemplate from '../../assets/cardTemplate.svg';
import fourCardTemplate from '../../assets/fourCardTemplate.svg';
import sixCardTemplate from '../../assets/sixCardTemplate.svg';
import heroTemplate from '../../assets/heroTemplate.svg';
import heroWithImageTemplate from '../../assets/heroWithImageTemplate.svg';
import twoColumnFeaturedTemplate from '../../assets/twoColumnFeaturedTemplate.svg';
import footerWithImageTemplate from '../../assets/footerWithImageTemplate.svg';
import { generateUUID } from '../../helpers/utils';
import { useSectionMutations } from '../../mutations/sections';
import { useActiveSection } from '../../queries/guides';
import { useIsEntityChanging } from '../../stores/socketsStore';
import { useGuideStore } from '../../stores/guideStore';
import { TemplatesEnum } from '../../constants/templates/constants';

export interface CreateModalProps {
  onCancel: () => void;
}

const SectionTemplateModal: React.FC<CreateModalProps> = ({ onCancel }) => {
  const activeSectionTemplate = useInspectorStore(s => s.activeTemplate);
  const setSectionTemplate = useInspectorStore(s => s.setTemplate);
  const activeGuideId = useGuideStore(s => s.activeGuideId);
  const { addSection, addSectionTemplate } = useSectionMutations();
  const isChangingEntity = useIsEntityChanging(activeGuideId);
  const { sectionIndex } = useActiveSection();
  const { translate } = useTranslate();

  const handleAddSectionTemplate = () => {
    const sectionId = generateUUID();
    if (activeSectionTemplate === TemplatesEnum.WHITE_TEMPLATE) {
      addSection.mutate({ sectionId, sectionPosition: sectionIndex + 1 });
    }

    addSectionTemplate.mutate({
      sectionId,
      sectionTemplate: activeSectionTemplate,
      sectionPosition: sectionIndex + 1,
    });
  };

  const SectionTemplateCard = ({
    sectionTemplate,
    templateImg,
  }: {
    sectionTemplate: TemplatesEnum;
    templateImg: string;
  }) => (
    <Card
      variant="clean"
      onClick={() => setSectionTemplate(sectionTemplate)}
      onSelect={() => setSectionTemplate(sectionTemplate)}
      isSelected={activeSectionTemplate === sectionTemplate}
    >
      <Card.Frame height="fixed">
        <Card.Images>
          <Card.Img src={templateImg} />
        </Card.Images>
      </Card.Frame>
    </Card>
  );

  return (
    <ModalBase isClosedOnOverlayClick isOpen onClose={onCancel} size="medium">
      <ModalBase.Header title={'Select section template'} />
      <ModalBase.Content>
        <Grid>
          <Tooltip content={translate('WIDGET_MENU.ADD_SECTION_BOTTOM')}>
            <SectionTemplateCard
              sectionTemplate={TemplatesEnum.WHITE_TEMPLATE}
              templateImg={whiteTemplate}
            />
          </Tooltip>

          <SectionTemplateCard
            sectionTemplate={TemplatesEnum.HERO_TEMPLATE}
            templateImg={heroTemplate}
          />
          <SectionTemplateCard
            sectionTemplate={TemplatesEnum.TWO_COLUMN_FEATURED_TEMPLATE}
            templateImg={twoColumnFeaturedTemplate}
          />
          <SectionTemplateCard
            sectionTemplate={TemplatesEnum.TWO_CARD_TEMPLATE}
            templateImg={cardTemplate}
          />
          <SectionTemplateCard
            sectionTemplate={TemplatesEnum.FOUR_CARD_TEMPLATE}
            templateImg={fourCardTemplate}
          />
          <SectionTemplateCard
            sectionTemplate={TemplatesEnum.SIX_CARD_TEMPLATE}
            templateImg={sixCardTemplate}
          />
          <SectionTemplateCard
            sectionTemplate={TemplatesEnum.TEXT_TEMPLATE}
            templateImg={textTemplate}
          />

          <SectionTemplateCard
            sectionTemplate={TemplatesEnum.FOOTER_WITH_IMAGE_TEMPLATE}
            templateImg={footerWithImageTemplate}
          />
          <SectionTemplateCard
            sectionTemplate={TemplatesEnum.HERO_WITH_IMAGE_TEMPLATE}
            templateImg={heroWithImageTemplate}
          />
        </Grid>
      </ModalBase.Content>
      <ModalBase.Footer
        actionPrimary={
          <PrimaryButton
            isDisabled={isChangingEntity}
            data-testid="submitButton"
            onClick={handleAddSectionTemplate}
            isLoading={isChangingEntity}
          >
            <Translate id="CREATE_MODAL.DEFAULT_BUTTON_LABEL" />
          </PrimaryButton>
        }
        actionSecondary={
          <Button
            variant="secondary"
            onClick={onCancel}
            isDisabled={isChangingEntity}
          >
            <Translate id="CREATE_MODAL.CANCEL_BUTTON_LABEL" />
          </Button>
        }
      />
    </ModalBase>
  );
};

export default SectionTemplateModal;

const Grid = styled.div<{ size?: string }>`
  display: grid;
  grid-template-columns: repeat(3, 250px);
  gap: ${token.spacing6};
  align-items: flex-start;
`;
